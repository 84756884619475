import axios from 'axios';
import { getAuth } from "firebase/auth";

export default class {

    constructor(api) {
        this.urlApi = api;
    }

    async setBearerToken(token) {
        this.token = token;
    }

    async headers() {

        let token;
        if(typeof this.token == 'string') {
            token = this.token;
            this.token = null;
        } else {

            if(getAuth().currentUser != null) {

                token = await getAuth().currentUser.getIdToken();

                return { 
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                };

            } else {

                return { 
                    'Accept': 'application/json'
                }
            }
        }
    }

    async upload(files, options) {

        if(typeof options != "object" || options == null)
            options = {};

        let erro = !(typeof files == "object" && files != null);

        if(!erro) {

            if(Array.isArray(files)) {

                if(files.length == 0)
                    erro = true;

            } else {

                files = [files];

            }

        }

        if(erro) {
            return {
                success: false,
                message: "Nenhum arquivo selecionado!",
                error: {
                    code: "UPLOAD_FILE_MISSING",
                    message: "Nenhum arquivo informado para enviar.",
                    original: null
                }
            };
        }

        const options_default = {
            onProgress: () => {}, 
        };

        options = {...options_default, ...options};

        const headers = await this.headers();

        headers["Content-Type"] = files[0].type;
        headers["X-File-Name"] = files[0].name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        try {

            const response = await axios({
                method: "post",
                url: this.urlApi,
                data: files[0],
                headers: headers,
                onUploadProgress: data => {
                    let progress = data.progress;
                    if(data.progress === undefined || data.progress == null)
                        progress = parseFloat(((100 * data.loaded) / data.total).toFixed(2));
                    else
                        progress = parseFloat((100*progress).toFixed(2));
                    options.onProgress(progress);
                }
            });

            return response.data;

        } catch (error) {
            
            console.error(error);

            return {
                success: false,
                message: "Falha ao enviar o arquivo!",
                error: {
                    code: "UPLOAD_GENERIC_ERROR",
                    message: "Falha ao enviar o arquivo.",
                    original: error
                }
            };

        }

    }

}