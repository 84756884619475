import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      name: 'home',
      component: () => import('../views/Home.vue'),
      meta: {
        requiresAuth: true,
        withLayout: true,
      }
    },

    // #region - Usuarios //

    {
      path: "/login",
      name: 'login',
      component: () => import("../views/Login.vue"),
      meta: {
        requiresAuth: false,
        withLayout: false,
      }
    },
    {
      path: "/login/alterar-senha",
      name: 'login-recuperacao',
      component: () => import("../views/RecuperacaoSenha.vue"),
      meta: {
        requiresAuth: false,
        withLayout: false,
      }
    },
    {
      path: "/cadastro-usuario/",
      component: () => import("../views/CadastroUsuario.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      },
      children: [
        {
          path: "/cadastro-usuario/gerais",
          component: () => import("../components/stepsCadastroUsuario/Gerais.vue"),
        },
      ]
    },
    {
      path: "/usuarios/",
      name: 'usuarios',
      component: () => import("../views/ListaUsuarios.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      }
    },
    {
      path: "/usuario/:id",
      name: 'usuario-id',
      component: () => import("../views/Usuario.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      }
    },
    // #endregion - Usuario //

    // #region - Lista de Entidades

    {
      path: "/entidades",
      name: 'entidades',
      component: () => import("../views/ListaEntidades.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      }
    },

   // #endregion - Lista de Entidades

    // #region - Auditadas //

    {
      path: "/cadastro-auditada/:id",
      component: () => import("../views/CadastroAuditada.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      },
    },

    {
      path: "/cadastro-auditada",
      component: () => import("../views/CadastroAuditada.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      },
      children: [
        {
          path: "/cadastro-auditada/gerais",
          component: () => import("../components/stepsCadastroAuditada/Gerais.vue"),
        },
        {
          path: "/cadastro-auditada/previdencia",
          component: () => import("../components/stepsCadastroAuditada/Previdencia.vue"),
        },
        {
          path: "/cadastro-auditada/certificacoes",
          component: () => import("../components/stepsCadastroAuditada/CertificacaoIsencao.vue"),
        },
        {
          path: "/cadastro-auditada/gestao",
          component: () => import("../components/stepsCadastroAuditada/Gestao.vue"),
        },
        {
          path: "/cadastro-auditada/contatos",
          component: () => import("../components/stepsCadastroAuditada/Contatos.vue"),
        },
      ]
    },
    {
      path: "/entidade-auditada/:id",
      component: () => import("../views/Auditada.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      }
    },

    // #endregion - Auditadas //

    // #region - Financiadoras 
    {
      path: "/cadastro-financiadora/:id",
      component: () => import("../views/CadastroFinanciadora.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      },
    },
    {
      path: "/cadastro-financiadora",
      name: 'cds-financiadora',
      component: () => import("../views/CadastroFinanciadora.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      },
      children: [
        {
          path: "/cadastro-financiadora/gerais",
          component: () => import("../components/stepsCadastroFinanciadora/Gerais.vue"),
        },
        {
          path: "/cadastro-financiadora/contatos",
          component: () => import("../components/stepsCadastroFinanciadora/Contatos.vue"),
        },
      ]
    },
    {
      path: "/entidade-financiadora/:id",
      component: () => import("../views/Financiadora.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      }
    },

    // #endregion - Financiadoras 

    // #region - Empresas //
    {
      path: "/cadastro-empresa/:id",
      component: () => import("../views/CadastroEmpresa.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      },
    },
    {
      path: "/cadastro-empresa",
      component: () => import("../views/CadastroEmpresa.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      },
      children: [
        {
          path: "/cadastro-empresa/gerais",
          component: () => import("../components/stepsCadastroEmpresa/Gerais.vue"),
        },
        {
          path: "/cadastro-empresa/contatos",
          component: () => import("../components/stepsCadastroEmpresa/Contatos.vue"),
        },
      ]
    },
    {
      path: "/empresas",
      name: 'empresas',
      component: () => import("../views/ListaEmpresas.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      },
    },
    {
      path: "/empresa/:id",
      name: 'empresa-id',
      component: () => import("../views/Empresa.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      }
    },

    // #endregion - Empresas //

    // #region - Projetos //
    {
      path: "/cadastro-projeto/:id",
      component: () => import("../views/CadastroProjeto.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      },
    },
    {
      path: "/cadastro-projeto",
      name: 'cds-projetos',
      component: () => import("../views/CadastroProjeto.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      },
      children: [
        {
          path: "/cadastro-projeto/gerais",
          component: () => import("../components/stepsCadastroProjeto/Gerais.vue"),
        },
        {
          path: "/cadastro-projeto/orcamentos",
          component: () => import("../components/stepsCadastroProjeto/Orcamentos.vue"),
        },
        {
          path: "/cadastro-projeto/bancos-veiculos",
          component: () => import("../components/stepsCadastroProjeto/BancosVeiculos.vue"),
        },
      ]
    },
    {
      path: "/projetos",
      name: 'projetos',
      component: () => import("../views/ListaProjetos.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      },
    },
    {
      path: "/projetos/:id",
      name: 'projetos-id',
      component: () => import("../views/Projeto.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
        bodyClass: 'bg-gray-100'
      },
    },
    // #endregion - Projetos //

    {
      path: "/importador",
      name: 'importador',
      component: () => import("../views/Importador.vue"),
      meta: {
        requiresAuth: false,
        withLayout: true,
      },
    },
  ]
})

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user)
      },
      reject
    )
  })
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      alert("Acesso restrito");
      next('/login');
    }
  } else {
    next();
  }
});

export default router