<template>
  <Loading />
  <Toast />
  <ConfirmDialog />
  <div v-if="withLayout">

    <div class="fixed inset-0 bg-gray-900/80 z-50 lg:hidden" v-show="menuCSS.mobile"></div>

    <!-- Static sidebar for desktop -->
    <div id="menuDesktop"
      :class="menuCSS.container + (!menuCSS.mobile ? ' left-[-100%] lg:left-0' : ' left-0') + ' fixed lg:inset-y-0 z-50 lg:flex lg:flex-col'"
      @mouseover="menuCSS.full = true" @mouseout="menuCSS.full = (menuCSS.fixed) ? true : false">

      <button class="absolute p-4 right-[-3.5rem] lg:hidden" type="button" @click="menuCSS.mobile = false">
        <XMarkIcon class="text-white h-6 w-6" />
      </button>

      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        :class="(menuCSS.mobile ? 'h-screen ' : '') + 'flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 dark:border-blue-900/40 bg-white dark:bg-slate-800'">
        <div class="h-16 flex items-center relative gap-[4.5rem]"
          :class="{ 'pl-8 pr-4': menuCSS.full, 'px-4': !menuCSS.full }">
          <img href="#" class="max-h-8 w-auto cursor-pointer" :src="isDark?logoDark:logoLight" alt="IDEA">

          <span class="cursor-pointer text-slate-900 dark:text-white text-2xl pr-4 hidden lg:block"
            @click="menuCSS.fixed = !menuCSS.fixed">
            <div
              class="h-4 w-4 border-[1.5px] border-slate-900 dark:border-white rounded-full transition-all duration-150"
              :class="menuCSS.fixed
                ? 'ring-2 ring-inset ring-offset-4 ring-black-900 dark:ring-white bg-slate-900 dark:bg-white dark:ring-offset-slate-700'
                : ''
                "></div>
          </span>
        </div>

        <ul role="list" class="flex flex-col gap-1 px-2">

          <li
            :class="[$route.path == '/' ? 'bg-blue-back dark:bg-slate-700' : 'hover:bg-blue-back dark:hover:bg-slate-700', 'text-gray-700 dark:text-white  group-item group w-full rounded-md cursor-pointer']">
            <router-link to="/">
              <div
                class="flex items-center group/edit select-none self-stretch transition-all duration-200 group-active:scale-110 hover:scale-110">
                <a :class="[$route.path == '/' ? 'text-blue-idea dark:text-sky-300'
                  : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300'
                  , 'text-gray-700 dark:text-white flex gap-x-3 p-2 text-sm leading-6 font-semibold']">
                  <div class="w-1 rounded-xl h-7 bg-transparent transition-colors duration-200 relative overflow-hidden">
                    <div
                      :class="[$route.path == '/' ? 'translate-y-0 group-hover:translate-y-0 bg-blue-idea dark:bg-sky-300 transition-all duration-300' : 'group-hover:translate-y-0 ', ' absolute top-0 left-0 w-full h-[102%] translate-y-full bg-blue-idea dark:bg-sky-300 transition-all duration-300']">
                    </div>
                  </div>
                  <HomeIcon
                    :class="[$route.path == '/' ? 'text-blue-idea dark:text-sky-300' : 'text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'h-6 w-6 shrink-0']" />
                  <span v-show="menuCSS.full"
                    :class="[$route.path == '/' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300']">Dashboard</span>
                </a>
              </div>
            </router-link>
          </li>

          <li
            :class="[$route.path == '/projetos' ? 'bg-blue-back dark:bg-slate-700' : 'hover:bg-blue-back dark:hover:bg-slate-700', 'text-gray-700 dark:text-white group/item group w-full rounded-md cursor-pointer']">
            <router-link to="/projetos">
              <div
                class="flex items-center group/edit select-none group-active:scale-110 hover:scale-110 self-stretch transition-all duration-200">
                <a
                  :class="[$route.path == '/projetos' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'text-gray-700 dark:text-white flex gap-x-3 p-2 text-sm leading-6 font-semibold']">
                  <div class="w-1 rounded-xl h-7 bg-transparent transition-colors duration-200 relative overflow-hidden">
                    <div
                      :class="[$route.path == '/projetos' ? 'translate-y-0 group-hover:translate-y-0 bg-blue-idea dark:bg-sky-300 transition-all duration-300' : 'group-hover:translate-y-0 ', ' absolute top-0 left-0 w-full h-[102%] translate-y-full bg-blue-idea dark:bg-sky-300 transition-all duration-300']">
                    </div>
                  </div>
                  <FolderIcon
                    :class="[$route.path == '/projetos' ? 'text-blue-idea dark:text-sky-300' : 'text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'h-6 w-6 shrink-0']" />
                  <span v-show="menuCSS.full"
                    :class="[$route.path == '/projetos' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300']">Projetos</span>
                </a>
              </div>
            </router-link>
          </li>

          <li
            :class="[$route.path == '/entidades' ? 'bg-blue-back dark:bg-slate-700' : 'hover:bg-blue-back dark:hover:bg-slate-700', 'text-gray-700 dark:text-white group/item group w-full rounded-md cursor-pointer']">
            <router-link to="/entidades">
              <div
                class="flex items-center group/edit select-none group-active:scale-110 hover:scale-110 self-stretch transition-all duration-200">
                <a
                  :class="[$route.path == '/entidades' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'text-gray-700 dark:text-white flex gap-x-3 p-2 text-sm leading-6 font-semibold']">
                  <div class="w-1 rounded-xl h-7 bg-transparent transition-colors duration-200 relative overflow-hidden">
                    <div
                      :class="[$route.path == '/entidades' ? 'translate-y-0 group-hover:translate-y-0 bg-blue-idea dark:bg-sky-300 transition-all duration-300' : 'group-hover:translate-y-0 ', ' absolute top-0 left-0 w-full h-[102%] translate-y-full bg-blue-idea dark:bg-sky-300 transition-all duration-300']">
                    </div>
                  </div>
                  <BuildingLibraryIcon
                    :class="[$route.path == '/entidades' ? 'text-blue-idea dark:text-sky-300' : 'text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'h-6 w-6 shrink-0']" />
                  <span v-show="menuCSS.full"
                    :class="[$route.path == '/entidades' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300']">Entidades</span>
                </a>
              </div>
            </router-link>
          </li>

          <li
            :class="[$route.path == '/empresas' ? 'bg-blue-back dark:bg-slate-700' : 'hover:bg-blue-back dark:hover:bg-slate-700', 'text-gray-700 dark:text-white group/item group w-full rounded-md cursor-pointer']">
            <router-link to="/empresas">
              <div
                class="flex items-center group/edit select-none group-active:scale-110 hover:scale-110 self-stretch transition-all duration-200">
                <a
                  :class="[$route.path == '/empresas' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'text-gray-700 dark:text-white flex gap-x-3 p-2 text-sm leading-6 font-semibold']">
                  <div class="w-1 rounded-xl h-7 bg-transparent transition-colors duration-200 relative overflow-hidden">
                    <div
                      :class="[$route.path == '/empresas' ? 'translate-y-0 group-hover:translate-y-0 bg-blue-idea dark:bg-sky-300 transition-all duration-300' : 'group-hover:translate-y-0 ', ' absolute top-0 left-0 w-full h-[102%] translate-y-full bg-blue-idea dark:bg-sky-300 transition-all duration-300']">
                    </div>
                  </div>
                  <BuildingStorefrontIcon
                    :class="[$route.path == '/empresas' ? 'text-blue-idea dark:text-sky-300' : 'text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'h-6 w-6 shrink-0']" />
                  <span v-show="menuCSS.full"
                    :class="[$route.path == '/empresas' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300']">Empresas</span>
                </a>
              </div>
            </router-link>
          </li>

          <li
            :class="[$route.path == '/usuarios' ? 'bg-blue-back dark:bg-slate-700' : 'hover:bg-blue-back dark:hover:bg-slate-700', 'text-gray-700 dark:text-white group/item group w-full rounded-md cursor-pointer']">
            <router-link to="/usuarios">
              <div
                class="flex items-center group/edit select-none group-active:scale-110 hover:scale-110 self-stretch transition-all duration-200">
                <a
                  :class="[$route.path == '/usuarios' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'text-gray-700 dark:text-white flex gap-x-3 p-2 text-sm leading-6 font-semibold']">
                  <div class="w-1 rounded-xl h-7 bg-transparent transition-colors duration-200 relative overflow-hidden">
                    <div
                      :class="[$route.path == '/usuarios' ? 'translate-y-0 group-hover:translate-y-0 bg-blue-idea dark:bg-sky-300 transition-all duration-300' : 'group-hover:translate-y-0 ', ' absolute top-0 left-0 w-full h-[102%] translate-y-full bg-blue-idea dark:bg-sky-300 transition-all duration-300']">
                    </div>
                  </div>
                  <UserGroupIcon
                    :class="[$route.path == '/usuarios' ? 'text-blue-idea dark:text-sky-300' : 'text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'h-6 w-6 shrink-0']" />
                  <span v-show="menuCSS.full"
                    :class="[$route.path == '/usuarios' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300']">Usuários</span>
                </a>
              </div>
            </router-link>
          </li>

          <li class="'w-full rounded-md cursor-pointer hover:bg-blue-back dark:hover:bg-slate-700">

            <div class="flex flex-col select-none">
              <div class="group-active:scale-110 hover:scale-110 self-stretch transition-all duration-200 group-item group">
                <a class="group/edit text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300 flex gap-x-3 p-2 text-sm leading-6 font-semibold"
                  aria-controls="sub-menu-1" aria-expanded="false"
                  @click="expanded['cadastros'] = !expanded['cadastros']">
                  <div class="w-1 rounded-xl h-7 bg-transparent transition-colors duration-200 relative overflow-hidden">
                    <div
                      :class="[expanded['cadastros'] ? 'translate-y-0' : 'group-hover/edit:translate-y-0', ' absolute top-0 left-0 w-full h-[102%] translate-y-full bg-blue-idea dark:bg-sky-300 transition-all duration-300']">
                    </div>
                  </div>
                  <PencilSquareIcon
                    :class="[expanded['cadastros'] ? 'text-blue-idea dark:text-sky-300' : 'text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'h-6 w-6 shrink-0']" />
                  <span v-show="menuCSS.full"
                    :class="[expanded['cadastros'] ? 'text-blue-idea dark:text-sky-300' : '', 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300']">Cadastros</span>
                  <ChevronRightIcon v-show="menuCSS.full"
                    :class="[expanded['cadastros'] ? 'text-blue-idea dark:text-sky-300 rotate-90' : 'text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'h-6 w-6 shrink-0']" />
                </a>
              </div>

              <ul class="mt-1 pl-8 pr-2" id="sub-menu-1" v-show="expanded['cadastros'] && menuCSS.full" role="list">

                <li
                  :class="[$route.path == '/cadastro-auditada/gerais' ? 'bg-blue-items dark:bg-slate-800' : 'hover:bg-blue-items dark:hover:bg-slate-800', 'text-gray-700 dark:text-white group/item group w-full rounded-md cursor-pointer']">
                  <router-link to="/cadastro-auditada">
                    <div
                      class="flex items-center select-none group-active:scale-110 hover:scale-110 self-stretch transition-all duration-200 group/edit">
                      <a
                        :class="[$route.path == '/cadastro-auditada/gerais' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'text-gray-700 dark:text-white flex gap-x-3 p-2 text-sm leading-6 font-semibold']">

                        <BuildingOfficeIcon
                          :class="[$route.path == '/cadastro-auditada/gerais' ? 'text-blue-idea dark:text-sky-300' : 'text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'h-6 w-6 shrink-0']" />
                        <span
                          :class="[$route.path == '/cadastro-auditada/gerais' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300']">Auditadas</span>
                      </a>

                    </div>
                  </router-link>
                </li>

                <li
                  :class="[$route.path == '/cadastro-financiadora/gerais' ? 'bg-blue-items dark:bg-slate-800' : 'hover:bg-blue-items dark:hover:bg-slate-800', 'text-gray-700 dark:text-white group/item group w-full rounded-md cursor-pointer']">
                  <router-link to="/cadastro-financiadora">
                    <div
                      class="flex items-center select-none group-active:scale-110 hover:scale-110 self-stretch transition-all duration-200 group/edit">
                      <a
                        :class="[$route.path == '/cadastro-financiadora/gerais' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'text-gray-700 dark:text-white flex gap-x-3 p-2 text-sm leading-6 font-semibold']">

                        <BuildingOfficeIcon
                          :class="[$route.path == '/cadastro-financiadora/gerais' ? 'text-blue-idea dark:text-sky-300' : 'text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'h-6 w-6 shrink-0']" />
                        <span
                          :class="[$route.path == '/cadastro-financiadora/gerais' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300']">Financiadoras</span>
                      </a>
                    </div>
                  </router-link>
                </li>

                <li
                  :class="[$route.path == '/cadastro-empresa/gerais' ? 'bg-blue-items dark:bg-slate-800' : 'hover:bg-blue-items dark:hover:bg-slate-800', 'text-gray-700 dark:text-white group/item group w-full rounded-md cursor-pointer']">
                  <router-link to="/cadastro-empresa">
                    <div
                      class="flex items-center select-none group-active:scale-110 hover:scale-110 self-stretch transition-all duration-200 group/edit">
                      <a
                        :class="[$route.path == '/cadastro-empresa/gerais' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'text-gray-700 dark:text-white flex gap-x-3 p-2 text-sm leading-6 font-semibold']">

                        <BuildingOfficeIcon
                          :class="[$route.path == '/cadastro-empresa/gerais' ? 'text-blue-idea dark:text-sky-300' : 'text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'h-6 w-6 shrink-0']" />
                        <span
                          :class="[$route.path == '/cadastro-empresa/gerais' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300']">Empresas</span>
                      </a>
                    </div>
                  </router-link>
                </li>

                <li
                  :class="[$route.path == '/cadastro-projeto/gerais' ? 'bg-blue-items dark:bg-slate-800' : 'hover:bg-blue-items dark:hover:bg-slate-800', 'text-gray-700 dark:text-white group/item group w-full rounded-md cursor-pointer']">
                  <router-link to="/cadastro-projeto">
                    <div
                      class="flex items-center select-none group-active:scale-110 hover:scale-110 self-stretch transition-all duration-200 group/edit">
                      <a
                        :class="[$route.path == '/cadastro-projeto/gerais' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'text-gray-700 dark:text-white flex gap-x-3 p-2 text-sm leading-6 font-semibold']">

                        <FolderPlusIcon
                          :class="[$route.path == '/cadastro-projeto/gerais' ? 'text-blue-idea dark:text-sky-300' : 'text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'h-6 w-6 shrink-0']" />
                        <span
                          :class="[$route.path == '/cadastro-projeto/gerais' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300']">Projetos</span>
                      </a>
                    </div>
                  </router-link>
                </li>

                <li
                  :class="[$route.path == '/cadastro-usuario/gerais' ? 'bg-blue-items dark:bg-slate-800' : 'hover:bg-blue-items dark:hover:bg-slate-800', 'text-gray-700 dark:text-white group/item group w-full rounded-md cursor-pointer']">
                  <router-link to="/cadastro-usuario/gerais">
                    <div
                      class="flex items-center select-none group-active:scale-110 hover:scale-110 self-stretch transition-all duration-200 group/edit">
                      <a
                        :class="[$route.path == '/cadastro-usuario/gerais' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'text-gray-700 dark:text-white flex gap-x-3 p-2 text-sm leading-6 font-semibold']">

                        <UsersIcon
                          :class="[$route.path == '/cadastro-usuario/gerais' ? 'text-blue-idea dark:text-sky-300' : 'text-gray-700 dark:text-white group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300', 'h-6 w-6 shrink-0']" />
                        <span
                          :class="[$route.path == '/cadastro-usuario/gerais' ? 'text-blue-idea dark:text-sky-300' : 'group-hover/edit:text-blue-idea dark:group-hover/edit:text-sky-300']">Usuários</span>
                      </a>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div :class="{ 'lg:pl-20': !menuCSS.full, 'lg:pl-56': menuCSS.full }">
      <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 dark:border-blue-dark bg-white dark:bg-slate-800 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 dark:text-white lg:hidden" @click="menuCSS.mobile = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Separator -->
        <div class="h-6 w-px bg-slate-100 dark:bg-white lg:hidden" aria-hidden="true"></div>

        <div class="flex flex-1 gap-x-4 self-stretch justify-end lg:gap-x-6">

          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <button type="button" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
              <span class="sr-only">View notifications</span>
              <ThemeSwitcher />
            </button>
            <!-- Separator -->
            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-slate-100 dark:lg:bg-white" aria-hidden="true"></div>

            <!-- Profile dropdown -->
            <div class="relative">
              <button type="button" @click="open = !open" class="-m-1.5 flex items-center p-1.5" id="user-menu-button"
                aria-expanded="false" aria-haspopup="true">
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full bg-gray-100" :src="(usuario != null && usuario.fotoPerfil !== undefined) ? usuario.fotoPerfil : userDefault" alt="Foto de Perfil">
                <span class="hidden lg:flex lg:items-center">
                  <span class="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-white" aria-hidden="true">{{
                    (usuario) ?
                    usuario.nome : "" }}</span>
                  <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400 dark:text-white" aria-hidden="true" />
                </span>
              </button>

              <ul
                :class="((!open) ? 'hidden ' : '') + 'absolute right-0 z-10 mt-2.5 w-32 p-1 origin-top-right rounded-md bg-white dark:bg-slate-800 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'"
                role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">

                <li
                  class="block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-white cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-600 rounded-lg"
                  @click="openProfile">
                  <a tabindex="-1" id="user-menu-item-0">Ver Perfil</a>
                </li>

                <li
                  class="block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-white cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-600 rounded-lg"
                  @click="logout">
                  <a tabindex="-1" id="user-menu-item-1">Sair</a>
                </li>

              </ul>

            </div>
          </div>
        </div>
      </div>

      <main class="p-4 lg:p-6">
        <div class="rounded-xl">
          <RouterView />
        </div>
      </main>
    </div>
  </div>
  <RouterView v-else />
  <!-- TODO Revisar se for mesmo usar
  <ConfirmDialog group="headless" :pt="{root: { class: 'w-[85vw] sm:w-[50vw] lg:w-[500px]' }}">
        <template #container="{ message, acceptCallback, rejectCallback }">
            <div class="flex flex-col gap-4 items-center p-8 rounded-xl bg-white dark:bg-gray-800 dark:border-gray-500">
                <div class="rounded-full bg-sky-500 inline-flex justify-center items-center h-24 w-24 -mt-20">
                    <i class="pi pi-question text-4xl text-white dark:text-gray-800"></i>
                </div>
                <span class="font-medium text-[1.25rem] block mb-2 mt-4 text-gray-900 dark:text-white">{{ message.message }}</span>

                <div class="flex flex-col gap-3 sm:flex-row">
                    <Button label="Sim" severity="info" @click="acceptCallback" class="h-[35px] text-white dark:text-gray-800 bg-sky-500 border-sky-500 hover:bg-sky-400 hover:border-sky-400"></Button>
                    <Button label="Não, excluir!" outlined severity="danger" @click="rejectCallback" class="h-[35px] text-rose-400 bg-white dark:bg-transparent hover:bg-rose-50 dark:hover:bg-rose-500/20 border-rose-400 hover:text-red-500"></Button>
                </div>
                
            </div>
        </template>
  </ConfirmDialog>
  -->
</template>

<script>
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { RouterView } from "vue-router";

export default {

  components: {
    RouterView,
  },

  data() {
    return {
      isDark: false,
      withLayout: false,
      open: false,
      current: false,
      usuario: null,
      sidebarOpen: false,
      logoLight: require("./assets/imgs/logo-idea.svg"),
      logoDark: require("./assets/imgs/logo-idea-dark.svg"),
      userDefault: require('./assets/imgs/user-default.jpeg'),

      expanded: {
        "cadastros": false
      },

      menuCSS: {
        full: true,
        mobile: false,
        fixed: true,
        container: 'w-7/12 sm:w-4/12 md:w-3/12 lg:w-56',
        active: false,
      },

    }
  },

  created() {

    this.isDark = window.localStorage.getItem('AUDITORIA/MODO') === 'dark';

    const root = document.getElementsByTagName('html')[0];

    if(this.isDark)
      root.classList.add('dark');

    onAuthStateChanged(getAuth(), async (user) => {
      if (!user) {

        this.usuario = null;
        this.$router.replace("/login");

      } else {

        if (this.usuario != null)
          return;

        const res = await this.$api.get(`/usuarios/${user.uid}`);

        if (res.data.success) {
          this.usuario = res.data.data;
        } else {
          signOut(getAuth());
        }

      }
    });

  },

  watch: {

    $route(atual, anterior) {

      this.withLayout = this.$route.matched.some(
        (route) => route.meta.withLayout
      );

      if (
        typeof atual.meta == "object" &&
        typeof atual.meta.bodyClass == "string"
      ) {
        document.body.classList.add(atual.meta.bodyClass);
      }

      if (
        typeof anterior.meta == "object" &&
        typeof anterior.meta.bodyClass == "string"
      ) {
        document.body.classList.remove(anterior.meta.bodyClass);
      }

    },

    "menuCSS.full": function (full) {
      if (full) {
        this.menuCSS.container = 'w-7/12 sm:w-4/12 md:w-3/12 lg:w-56'; // Adiciona a Classe Certa desse Estado
      } else {
        this.menuCSS.container = 'lg:w-20'; // Adiciona a Classe Certa desse Estado
      }
    },

    "menuCSS.mobile": function (mobile) {
      if (mobile) {
        this.menuCSS.full = true;
      }
    }

  },

  methods: {

    logout() {
      this.$loading.show();
      this.open = false;
      signOut(getAuth());
      this.$loading.hide();
    },

    openProfile() {
      this.$loading.show();

      if (this.$utils.isMObject(this.usuario)) {
        this.$router.push(`/usuario/${this.usuario.id}`);
        this.open = false;
      }

      this.$loading.hide();
    },

  }
}
</script>

<style scoped>
#menuDesktop {
  transition: width 0.5s, left 0.5s;
}
</style>