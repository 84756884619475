export default {
    inputmask: {
        root: ({ props }) => ({
            class: [
                'w-full font-normal text-base text-gray-700 dark:text-white/80',
                'bg-white dark:bg-gray-800 border border-gray-300 dark:border-blue-900/40 hover:border-blue-500 dark:hover:border-blue-300 rounded-xl',
                'placeholder:text-gray-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)] transition duration-200 ease-in-out appearance-none',
                {
                    'text-lg px-4 py-4': props.size == 'large',
                    'text-xs px-2 py-2': props.size == 'small',
                    'text-base p-1.5': props.size == null
                }
            ]
        })
    }
}
