import _ from 'lodash';
import JSONDate from 'json-stringify-date';
import moment from "moment-timezone";

export default class {

    // #region Validadores //

    static isMObject(val) {
        if (typeof val != "object")
            return false;

        if (val == null)
            return false;

        if (Object.keys(val).length == 0)
            return false;

        return true;
    }

    static isEmail(val) {
    
        this.isValid(val);
        return /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i.test(val);

    }

    static isValidParams(params, fields) {

        if (!Array.isArray(fields) || fields.length == 0)
            return false;

        if ((typeof params != "object" || params == null) && (!Array.isArray(fields) || fields.length == 0)) {
            return false;
        }

        let erros = [];
        for (const f of fields) {
            if (!this.isValid(params[f]))
                erros.push(f);
        }

        return erros;

    }

    static isValid(val) {
        return !(typeof val == "undefined" || val == null || val === "" || val === 0);
    }

    static isGetStrNotEmpty(val) {
        if (typeof val != "string")
            return false;

        val = val.trim();

        if (val == "")
            return false;

        return val;
    }

    static validString(val, def = "") {
        if (typeof def != "string")
            def = "";

        if (typeof val == "string")
            return val;

        return def;
    }

    static validFloat(val, def = 0) {
        if (typeof def != "number")
            def = 0;

        if (typeof val == "number")
            return parseFloat(val.toFixed(2));

        return def;
    }

    static validInteger(val, def = 0) {
        if (typeof def != "number")
            def = 0;

        if (typeof val == "number")
            return parseInt(val);

        return def;
    }

    static validObject(val, def = {}) {
        if (typeof def != "object")
            def = {};

        if (typeof val == "object" && val != null)
            return val;

        return def;
    }

    static validDate(val, def = null) {
        if (typeof def != "object")
            def = null;

        if (_.isDate(val))
            return val;

        if (typeof val == "string" && val != "" && val.includes("T"))
            return new Date(val);

        return def;
    }

    static validIfDate(val) {
        if (_.isDate(val) || val == null || typeof val == "undefined")
            return val;
        if (/[0-2][0-3][0-9][0-9]-[0-1][0-9]-[0-3][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9]\.[0-9][0-9][0-9].*/.test(val.toString()))
            return new Date(val);
        return val;
    }

    static validArray(val, def = []) {
        if (typeof def != "object" || !Array.isArray(def))
            def = [];

        if (typeof val == "object" && val != null) {

            if (Array.isArray(val)) {
                return val;
            }

            const k = Object.keys(val);
            let i;
            for (i = 0; i < k.length && k[i] == i; i++);
            if (i == k.length)
                return Object.values(val);

        }

        return def;
    }

    static validBoolean(val, def = false) {
        if (typeof def != "boolean")
            def = false;

        if (typeof val == "boolean")
            return val;

        return def;
    }

    static isCPF(strCPF) {
        strCPF = strCPF.replace(/[^0-9]/g, "");

        var i;
        var Soma;
        var Resto;
        Soma = 0;

        if (strCPF == "00000000000") return false;

        for (i = 1; i <= 9; i++)
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (i = 1; i <= 10; i++)
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return false;

        return true;
    }

    static isCNPJ(cnpj) {
        var i;

        cnpj = cnpj.replace(/[^\d]+/g, "");

        if (cnpj == "") return false;

        if (cnpj.length != 14) return false;

        // Elimina CNPJs invalidos conhecidos
        if (
            cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999"
        )
            return false;

        // Valida DVs
        var tamanho = cnpj.length - 2;
        var numeros = cnpj.substring(0, tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;

        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        }

        var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

        if (resultado != digitos.charAt(0)) return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;

        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        }

        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

        if (resultado != digitos.charAt(1)) return false;

        return true;
    }

    // #endregion Validadores //

    // #region Manipulação de Objetos (OK Date) //

    static objNullPaths(obj, parent = "") {

        if (typeof obj != "object")
            return undefined;

        if (obj === null) {
            return parent;
        }

        let result = [];

        for (let [k, v] of Object.entries(obj)) {

            let nParent = parent;
            if (parent != "")
                nParent += "." + k;
            else
                nParent = k;

            const ret = this.objNullPaths(v, nParent);

            if (typeof ret == "string")
                result.push(ret);
            else if (typeof ret == "object" && Array.isArray(ret)) {
                result = _.union(result, ret);
            }

        }

        return result;

    }

    static processFullPathObject(val, parent = "") {

        if (typeof val != "object" || val == null || val instanceof Date)
            return val;

        if (parent != "")
            parent += ".";

        let result = {};
        for (const [k, v] of Object.entries(val)) {

            const nParent = parent + k;

            const r = this.processFullPathObject(v, nParent);

            result[nParent] = r;

        }

        return result;

    }

    static processResultsMultiDimensionalToOneDimension(val) {

        if (typeof val != "object" || val instanceof Date)
            return val;

        let paths = {};
        let roots = [];

        for (const [k, v] of Object.entries(val)) {

            if (typeof v == "object" && v != null && !(v instanceof Date)) {
                roots.push(k);

                const r = this.processResultsMultiDimensionalToOneDimension(v);

                paths = { ...paths, ...r.paths };
                roots = roots.concat(r.roots);

            } else {
                paths[k] = v;
            }

        }

        return {
            paths: paths,
            roots: roots,
        };

    }

    static multiDimensionalToOneDimension(val, withRoots = false) {

        let processed = this.processFullPathObject(val);
        processed = this.processResultsMultiDimensionalToOneDimension(processed);

        if (withRoots)
            return processed;
        return processed.paths;

    }

    static differenceOfObjects(__old, __new) {

        const _old_normalized = this.multiDimensionalToOneDimension(__old, true);
        const _new_normalized = this.multiDimensionalToOneDimension(__new, true);
        let _old = _old_normalized.paths;
        let _new = _new_normalized.paths;

        let difference = {};
        let toUpdate = {};
        let toRemove = [];

        for (const k of Object.keys(_new)) {
            if (typeof _old[k] == "undefined") {
                difference[k] = {
                    'old': undefined,
                    'new': _new[k]
                };
                toUpdate[k] = _new[k];
            }
            if (_new[k] === "remove" && _old[k] === "remove")
                _old[k] = "remove_error";
        }

        for (const k of Object.keys(_old)) {

            if (typeof _old[k] != typeof _new[k] || _old[k] != _new[k]) {

                if (typeof _new[k] == "undefined" || _new[k] === "remove") {

                    difference[k] = {
                        'old': _old[k],
                        'new': undefined
                    };
                    toRemove.push(k);
                } else {
                    difference[k] = {
                        'old': _old[k],
                        'new': _new[k]
                    };
                    toUpdate[k] = _new[k];
                }

            }

        }

        for (const r of _old_normalized.roots) {
            const b_root = _new_normalized.roots.indexOf(r) == -1;
            const c_new = Object.keys(_new).filter((k) => k.indexOf(r) == 0).length;
            const c_remove = toRemove.filter((k) => k.indexOf(r) == 0).length;
            if (c_new == 0 && c_remove > 0 || (b_root && c_remove > 0)) {
                toRemove = toRemove.filter((k) => k.indexOf(r) != 0);
                toRemove.push(r);
            }
        }

        for (const [r, v] of Object.entries(toUpdate)) {

            if (v === null || v == "remove") {
                delete toUpdate[r];
                toRemove = toRemove.filter((k) => k.indexOf(r) != 0);
                toRemove.push(r);
            }

        }

        return {
            difference: difference,
            remove: toRemove,
            update: toUpdate
        };

    }

    static unsetPathOfObject(obj, path) {

        if (typeof path != "string" || path == "")
            return false;

        path = path.split(".");

        if (path.length == 0)
            return false;

        const last = path.pop();

        for (const p of path) {
            if (typeof obj == "object")
                obj = obj[p];
            else {
                obj = null;
                break;
            }
        }

        if (obj != null) {

            if (typeof obj[last] == "undefined") {
                return false;
            }

            if (Array.isArray(obj)) {
                obj.splice(parseInt(last), 1);
            } else {
                delete obj[last];
            }

            return true;

        }

        return false;

    }

    static valFromPathOfObject(obj, path) {

        if (typeof path != "string" || path == "")
            return undefined;

        path = path.split(".");

        for (const p of path) {
            if (typeof obj == "object")
                obj = obj[p];
            else {
                obj = undefined;
                break;
            }
        }

        return obj;

    }

    static setFromPathOfObject(obj, path, value) {

        if (typeof path != "string" || path == "")
            return undefined;

        const formatoresRegx = /(\[[^\]]*\])|(\{[^}]*\})/g
        const dadosFormatadores = path.match(formatoresRegx);
        
        path = path.replace(formatoresRegx, "#").split(".");
        
        if(path.length == 0)
            return;

        const pArr = [];
        let iDF = 0;
        for(const [i,p] of Object.entries(path)) {
            if(p === "#") {
                path[i] = dadosFormatadores[iDF++];
                if(path[i].startsWith("[")) {
                    pArr.push(parseInt(i) - 1);
                    path[i] = path[i].replace("[","").replace("]","");
                } else {
                    path[i] = path[i].replace("{","").replace("}","");
                }
            }
        }

        let pObj = {};
        let nObj = pObj;
        for (let [i,p] of Object.entries(path)) {

            if(i == (path.length - 1)) {
                pObj[p] = value;
            } else {

                if(pObj[p] === undefined) {
                    if(pArr.includes(parseInt(i)))
                        pObj = pObj[p] = [];
                    else
                        pObj = pObj[p] = {};
                }

            }
        }

        _.merge(obj, nObj);

    }

    static isAttr(obj, path) {

        if (typeof path != "string" || path == "")
            return false;
    
        path = path.split(".");

        for (const [i,p] of Object.entries(path)) {
            if (typeof obj == "object")
                obj = obj[p];
            else {
                if(i == path.length-1 && typeof obj[p] != "undefined")
                    return true;
                return false
            }
        }

        return true;

    }

    static dateValues(obj) {
        if (typeof obj == "undefined" || obj == null)
            return obj;

        for (const [k, v] of Object.entries(obj)) {
            if (k == "id" || k == "_id") continue;
            if (typeof v == "object") {
                if (v instanceof Date)
                    continue;
                obj[k] = this.dateValues(v);
            } else {
                if(typeof v == "string" && /[0-2][0-3][0-9][0-9]-[0-1][0-9]-[0-3][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9]\.[0-9][0-9][0-9].*/.test(v))
                    obj[k] = new Date(v);
            }
        }
        return obj;
    }

    static objSerialize(obj) {
        let seen = [];
        return JSON.stringify(obj, function (key, val) {
            if (val != null && typeof val == "object") {
                if (seen.indexOf(val) >= 0) {
                    return;
                }
                seen.push(val);
            }
            return val;
        });
    }

    static getStdObject(obj, withDate = true) {
        if (typeof obj == "undefined" || obj == null)
            return null;

        const replacer = function (key, value) {
            if (value instanceof RegExp)
                return ("__REGEXP " + value.toString());
            else
                return value;
        }

        const reviver = function (key, value) {
            if (value === null) return value;
            if (value.toString().indexOf("__REGEXP ") == 0) {
                var m = value.split("__REGEXP ")[1].match(/\/(.*)\/(.*)?/);
                return new RegExp(m[1], m[2] || "");
            } else
                return value;
        }

        if (withDate)
            return JSONDate.parse(JSONDate.stringify(obj, replacer, 2), reviver);

        return JSON.parse(JSON.stringify(obj, replacer, 2), reviver);
    }

    // #endregion Manipulação de Objetos (OK Date) //

    // #region Formatadores //

    static formatStringSlice(string) {
        string = string.toString().replace(/[^0-9xX]/g, "");
            return string.slice(0, -1) + '-' + string.slice(-1);
    }

    static formatPhone(phone) {
        phone = phone.replace(/[^0-9]/g, "");
        return '(' + phone.substr(0, 2) + ') ' + phone.substr(2, phone.length - 6) + '-' + phone.substr(-4);
    }

    static formatCurrency(val, prefix = 'R$ ') {

        if (prefix === false)
            prefix = '';

        if (typeof val == "string")
            val = parseFloat(val);

        if (typeof val != "number" || isNaN(val))
            return prefix + "Erro";

        return prefix + (new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(val));

    }

    static calculaPorcentagem(valorInicial, valorFinal, isNumber = false) {

        if (typeof valorInicial != "number" || typeof valorFinal != "number" || valorFinal == 0 || valorInicial == 0) {
            return isNumber ? 0 : "0%";
        }

        let porcentagem;

        if (valorFinal >= valorInicial) {
            porcentagem = ((valorFinal - valorInicial) / valorInicial) * 100;
        } else {
            porcentagem = (100 - (valorInicial * 100) / valorFinal);
        }

        return isNumber ? porcentagem : (porcentagem.toFixed(2) + "%");

    }

    static formatStrDateToBr(date) {
        if (typeof date != "string" || date == "")
            return "";
        return date.split("-").reverse().join("/");
    }

    static formatStrDateToBD(date) {
        if (typeof date != "string" || date == "")
            return "";
        return date.split("/").reverse().join("-");
    }

    static formatStrDateTimeToBr(date) {
        if (typeof date != "string" || date == "")
            return "";
        var dateTime = date.split(" ");
        return dateTime[0].split("-").reverse().join("/") + " " + dateTime[1];
    }

    static formatStrDateTimeToBD(date) {
        if (typeof date != "string" || date == "")
            return "";
        var dateTime = date.split(" ");
        return dateTime[0].split("/").reverse().join("-") + " " + dateTime[1];
    }

    static formatObjDate(date, format = "DD/MM/YYYY HH:mm:ss") {
        if (typeof date != "undefined" && date != null && date != "") {
            return moment(date, true).tz('America/Sao_Paulo').format(format);
        }
        return "";
    }

    static strToDate(date, format = "DD/MM/YYYY") {

        if (typeof date == 'string' && date != '') {
            return moment(date, format, true).tz('America/Sao_Paulo').toDate();
        } else if (date instanceof Date) {
            return date;
        }

        return null;

    }

    static normalizeStr(str, caseSensitive = 'N') {
        if (typeof str != "string")
            return "";
        caseSensitive = caseSensitive.toLowerCase();
        str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").trim();
        if (caseSensitive == 'l')
            str = str.toLowerCase();
        else if (caseSensitive == 'u')
            str = str.toUpperCase();
        return str;
    }

    static capitalize(value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
    }

    // #region Formatadores //

    // #region Utilitários //

    static getDateRange(month = 0, withTime = false) {

        let dateIni = moment().tz("America/Sao_Paulo");
        let dateFin = moment().tz("America/Sao_Paulo");

        if (month > 0) {
            dateIni = dateIni.add(month, "months");
            dateFin = dateFin.add(month, "months");
        } else if (month < 0) {
            dateIni = dateIni.subtract(month, "months");
            dateFin = dateFin.subtract(month, "months");
        }

        dateIni = dateIni.startOf('month').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
        dateFin = dateFin.endOf('month').set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 0);

        return {
            inicial: dateIni.toDate(),
            final: dateFin.toDate(),
            inicialBR: dateIni.format((withTime) ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY"),
            finalBR: dateFin.format((withTime) ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY"),
            inicialISO: dateIni.format((withTime) ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"),
            finalISO: dateFin.format((withTime) ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"),
            inicialTimeStamp: parseInt(dateIni.format((withTime) ? "YYYYMMDDHHmmss" : "YYYYMMDD")),
            finalTimeStamp: parseInt(dateFin.format((withTime) ? "YYYYMMDDHHmmss" : "YYYYMMDD")),
            inicialMoment: dateIni,
            finalMoment: dateFin,
            filter: encodeURIComponent("~" + dateIni.format("YYYY-MM-DD") + "," + dateFin.format("YYYY-MM-DD"))
        };

    }

    static getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    // static dd(message_data = "", data = null, color = false) {
    //     if (typeof message_data != "string") {
    //         if (typeof data == "boolean")
    //             color = data;
    //         console.log(util.inspect(message_data, false, null, color));
    //     } else {
    //         console.log(message_data);
    //         console.log(util.inspect(data, false, null, color));
    //     }
    // }

    // #endregion Utilitários //

    static titleCase(texto) {

        if (typeof texto != "string")
            return texto;

        let palavras = texto.toLowerCase().split(" ");
        const ignorar = ["de", "da", "das", "do", "do(a)", "da(o)", "com", "a", "e", "o"];

        for (let i = 0; i < palavras.length; i++) {
            if (ignorar.includes(palavras[i]) == false && palavras[i].length > 0) {
                palavras[i] = palavras[i][0].toUpperCase() + palavras[i].slice(1);
            }
        }

        return palavras.join(" ");
    }

    static upperCase(texto) {
        return texto.toUpperCase();
    }

    static executaFuncaoEmArrayDeObjetos(arr, funcao) {
        for (let i = 0; i < arr.length; i++) {
            arr[i] = this.executaFuncaoEmObjeto(arr[i], funcao);
        }
        return arr;
    }

    static executaFuncaoEmObjeto(obj, funcao) {
        if (typeof obj == "undefined" || obj == null)
            return obj;

        for (const [k, v] of Object.entries(obj)) {
            if (k == "id") continue;
            if (typeof v == "object") {
                if (v instanceof Date)
                    continue;
                obj[k] = this.executaFuncaoEmObjeto(v, funcao);
            } else
                obj[k] = funcao(v);
        }
        return obj;
    }

    static isObjectClear(obj, options) {
        const default_options = {
            validBooleanValues: [true]
        };
        options = { ...default_options, ...options };
        if (typeof obj == "object" && obj != null) {
            for (const v of Object.values(obj)) {
                switch(typeof v) {
                    case "object": {
                        if(Array.isArray(v) && v.length > 0)
                            return false;
                        if (v instanceof Date)
                            return false;
                        if(this.isObjectClear(v) == false)
                            return false;
                        break;
                    }
                    case "string": {
                        if(v != "")
                            return false;
                        break;
                    }
                    case "number": {
                        if(v != 0)
                            return false;
                        break;
                    }
                    case "boolean": {
                        if(options.validBooleanValues.includes(v))
                            return false;
                    }
                }
            }
        }
        return true;
    }

    static phoneMask = (value) => {
        if (!value) return ""
        value = value.replace(/\D/g, '')
        value = value.replace(/(\d{2})(\d)/, "($1) $2")
        value = value.replace(/(\d)(\d{4})$/, "$1-$2")
        return value
    }

}
