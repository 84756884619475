export default {
    inputnumber: {
        root: 'w-full inline-flex font-normal',
        input: ({ props }) => ({
            class: [
                { 'rounded-tr-none rounded-br-none': props.showButtons && props.buttonLayout == 'stacked' }
            ]
        }),
        buttongroup: ({ props }) => ({
            class: [{ 'flex flex-col': props.showButtons && props.buttonLayout == 'stacked' }]
        }),
        incrementbutton: ({ props }) => ({
            class: [
                'flex !items-center !justify-center',
                {
                    'rounded-br-none rounded-bl-none rounded-bl-none !p-0 flex-1 w-[3rem]': props.showButtons && props.buttonLayout == 'stacked'
                }
            ]
        }),
        label: 'hidden',
        decrementbutton: ({ props }) => ({
            class: [
                'flex !items-center !justify-center',
                {
                    'rounded-tr-none rounded-tl-none rounded-tl-none !p-0 flex-1 w-[3rem]': props.showButtons && props.buttonLayout == 'stacked'
                }
            ]
        })
    }
}
