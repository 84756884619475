import axios from 'axios';
import { getAuth } from "firebase/auth";

export default class {

    constructor(api) {
        this.urlApi = api;
    }

    async setBearerToken(token) {
        this.token = token;
    }

    async headers() {

        let token;
        if(typeof this.token == 'string') {
            token = this.token;
            this.token = null;
        } else {

            if(getAuth().currentUser != null) {

                token = await getAuth().currentUser.getIdToken();

                return { 
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                };

            } else {

                return { 
                    'Accept': 'application/json'
                }
            }
        }
    }

    async get(endpoint) {

        if(typeof endpoint != "string")
            return null;

        return axios({
            method: 'get',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
        });

    }

    async post(endpoint, data = null) {

        if(typeof endpoint != "string" || typeof data != "object" || data == null)
            return null;

        return axios({
            method: 'post',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
            "data" : data
        });

    }

    async put(endpoint, data = null) {

        if(typeof endpoint != "string" || typeof data != "object" || data == null)
            return null;
    
        return axios({
            method: 'put',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
            "data" : data
        });

    }

    async delete(endpoint, data = null) {

        if(typeof endpoint != "string")
            return null;

        if(typeof data != "object" || data == null)
            data = {};

        return axios({
            method: 'delete',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
            "data" : data
        });

    }

}