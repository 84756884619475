export default {
    install: (app) => {

        const loading = {};

        loading.show = async () => {

            document.getElementById("preloading").classList.remove("hidden");

        };

        loading.message = (message = "", context = null) => {

            if(typeof context == "string") {
                message = context;
                context = null;
            }

            if(typeof message != "string")
                message = "";

            if(context == null) {
                context = document.getElementById("app");
            } else {
                context = context.$el;
            }

            for(const e of context.getElementsByClassName("app-loading splash-screen")) {

                const msgEl = e.getElementsByClassName("app-loading-message");

                if(message == "") {
                    if(msgEl.length == 1)
                        msgEl[0].remove();
                } else {
                    if(msgEl.length == 1) {
                        msgEl[0].innerHTML = message;
                    } else {

                        const div = document.createElement("div");
                        div.classList.add("app-loading-message");
                        div.innerHTML = message;
            
                        e.appendChild(div);

                    }
                }

            }

        };
        
        loading.hide = () => {

            document.getElementById("preloading").classList.add("hidden");

        };

        app.config.globalProperties.$loading = loading;

    },
};