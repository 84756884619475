export default {
    global: {
        css: `
        *[data-pd-ripple="true"]{
            overflow: hidden;
            position: relative;
        }
        span[data-p-ink-active="true"]{
            animation: ripple 0.4s linear;
        }
        @keyframes ripple {
            100% {
                opacity: 0;
                transform: scale(2.5);
            }
        }
    `
    },
    directives: {
        ripple: {
            root: {
                class: ['block absolute bg-white/50 rounded-full pointer-events-none'],
                style: 'transform: scale(0)'
            }
        }
    }
}
