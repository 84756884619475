// Nunca set true 
const isDev = window.location.hostname == "localhost";

import './assets/dist/output.css';
import 'primeicons/primeicons.css';
import '../node_modules/croppr/dist/croppr.min.css';

// Initial Config PrimeVue
import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import PrimeVue from "primevue/config";

// Config Auxiliaries
import API from './services/api';
import API_UPLOAD from './services/apiUpload';
import Utils from './utils/utils';
import LoadingService from './services/Loading/Loading.js';
import Loading from './services/Loading/Loading.vue';

// Config Firebase Auth
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from "firebase/auth";

// #region - Import Components PrimeVue

import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from "primevue/badgedirective";
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import CascadeSelect from 'primevue/cascadeselect';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import DeferredContent from 'primevue/deferredcontent';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice'
import Divider from 'primevue/divider';
import Dock from 'primevue/dock';
import Dropdown from 'primevue/dropdown';
import DynamicDialog from 'primevue/dynamicdialog';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import FocusTrap from 'primevue/focustrap';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import Row from 'primevue/row';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import Terminal from 'primevue/terminal';
import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import VirtualScroller from 'primevue/virtualscroller';
import Tailwind from 'primevue/passthrough/tailwind';

// #endregion - Import Components Primevue

// #region - Import Components Theme TailWind //

// Components Button
import TButton from './primevue-theme-tailwind/button/Button';
import TSpeedDial from './primevue-theme-tailwind/button/SpeedDial';
import TSplitButton from './primevue-theme-tailwind/button/SplitButton';

// Components Chart
import TCharts from './primevue-theme-tailwind/chart/Charts';

// Components Data
import TDataTable from './primevue-theme-tailwind/data/DataTable';
import TDataView from './primevue-theme-tailwind/data/DataView';
import TOrderList from './primevue-theme-tailwind/data/OrderList';
import TOrgChart from './primevue-theme-tailwind/data/OrgChart';
import TPaginator from './primevue-theme-tailwind/data/Paginator';
import TPickList from './primevue-theme-tailwind/data/PickList';
import TTimeline from './primevue-theme-tailwind/data/Timeline';
import TTree from './primevue-theme-tailwind/data/Tree';

// Components File
import TUpload from './primevue-theme-tailwind/file/Upload';

// Components Form
import TAutoComplete from './primevue-theme-tailwind/form/AutoComplete';
import TCalendar from './primevue-theme-tailwind/form/Calendar';
import TCascadeSelect from './primevue-theme-tailwind/form/CascadeSelect';
import TCheckbox from './primevue-theme-tailwind/form/Checkbox';
import TChips from './primevue-theme-tailwind/form/Chips';
import TColorPicker from './primevue-theme-tailwind/form/ColorPicker';
import TDropdown from './primevue-theme-tailwind/form/Dropdown';
import TEditor from './primevue-theme-tailwind/form/Editor';
import TInputMask from './primevue-theme-tailwind/form/InputMask';
import TInputNumber from './primevue-theme-tailwind/form/InputNumber';
import TInputSwitch from './primevue-theme-tailwind/form/InputSwitch';
import TInputText from './primevue-theme-tailwind/form/InputText';
import TKnob from './primevue-theme-tailwind/form/Knob';
import TListbox from './primevue-theme-tailwind/form/Listbox';
import TMultiSelect from './primevue-theme-tailwind/form/MultiSelect';
import TPassword from './primevue-theme-tailwind/form/Password';
import TRadioButton from './primevue-theme-tailwind/form/RadioButton';
import TRating from './primevue-theme-tailwind/form/Rating';
import TSelectButton from './primevue-theme-tailwind/form/SelectButton';
import TSlider from './primevue-theme-tailwind/form/Slider';
import TTextarea from './primevue-theme-tailwind/form/Textarea';
import TToggleButton from './primevue-theme-tailwind/form/ToggleButton';
import TTreeSelect from './primevue-theme-tailwind/form/TreeSelect';
import TTriStateCheckbox from './primevue-theme-tailwind/form/TriStateCheckbox';

// Components Media
import TCarousel from './primevue-theme-tailwind/media/Carousel';
import TGalleria from './primevue-theme-tailwind/media/Galleria';
import TImage from './primevue-theme-tailwind/media/Image';

// Components Menu
import TBreadcrumb from './primevue-theme-tailwind/menu/Breadcrumb';
import TContextMenu from './primevue-theme-tailwind/menu/ContextMenu';
import TDock from './primevue-theme-tailwind/menu/Dock';
import TMegaMenu from './primevue-theme-tailwind/menu/MegaMenu';
import TMenu from './primevue-theme-tailwind/menu/Menu';
import TMenubar from './primevue-theme-tailwind/menu/Menubar';
import TPanelMenu from './primevue-theme-tailwind/menu/PanelMenu';
import TSteps from './primevue-theme-tailwind/menu/Steps';
import TTabMenu from './primevue-theme-tailwind/menu/TabMenu';
import TTieredMenu from './primevue-theme-tailwind/menu/TieredMenu';

// Components Messages
import TInlineMessage from './primevue-theme-tailwind/messages/InlineMessage';
import TMessage from './primevue-theme-tailwind/messages/Message';
import TToast from './primevue-theme-tailwind/messages/Toast';

// Components Misc
import TAvatar from './primevue-theme-tailwind/misc/Avatar';
import TBadge from './primevue-theme-tailwind/misc/Badge';
import TBlockUI from './primevue-theme-tailwind/misc/BlockUI';
import TChip from './primevue-theme-tailwind/misc/Chip';
import TInplace from './primevue-theme-tailwind/misc/Inplace';
import TProgressBar from './primevue-theme-tailwind/misc/ProgressBar';
import TRipple from './primevue-theme-tailwind/misc/Ripple';
import TScrollTop from './primevue-theme-tailwind/misc/ScrollTop';
import TSkeleton from './primevue-theme-tailwind/misc/Skeleton';
import TTag from './primevue-theme-tailwind/misc/Tag';
import TTerminal from './primevue-theme-tailwind/misc/Terminal';

// Components Overlay
import TConfirmDialog from './primevue-theme-tailwind/overlay/ConfirmDialog';
import TConfirmPopup from './primevue-theme-tailwind/overlay/ConfirmPopup';
import TDialog from './primevue-theme-tailwind/overlay/Dialog';
import TDynamicDialog from './primevue-theme-tailwind/overlay/DynamicDialog';
import TOverlayPanel from './primevue-theme-tailwind/overlay/OverlayPanel';
import TSidebar from './primevue-theme-tailwind/overlay/Sidebar';
import TTooltip from './primevue-theme-tailwind/overlay/Tooltip';

// Components Panel
import TAccordion from './primevue-theme-tailwind/panel/Accordion';
import TCard from './primevue-theme-tailwind/panel/Card';
import TDivider from './primevue-theme-tailwind/panel/Divider';
import TFieldset from './primevue-theme-tailwind/panel/Fieldset';
import TPanel from './primevue-theme-tailwind/panel/Panel';
import TScrollPanel from './primevue-theme-tailwind/panel/ScrollPanel';
import TSplitter from './primevue-theme-tailwind/panel/Splitter';
import TTabView from './primevue-theme-tailwind/panel/TabView';
import TToolbar from './primevue-theme-tailwind/panel/Toolbar';

// Components Auxiliares
import ThemeSwitcher from './components/ThemeSwitcher.vue';

// #endregion - Import Components Theme TailWind

// #region - Components Heroi Icons //

import {
    UserIcon,
    ChevronDownIcon,
    MagnifyingGlassIcon,
    ChevronRightIcon,
    ChevronDoubleRightIcon,
    ChevronDoubleLeftIcon,
    TrashIcon,
    EyeIcon,
    PencilIcon,
    PrinterIcon,
    EnvelopeIcon
} from '@heroicons/vue/20/solid';

import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderPlusIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    PencilSquareIcon,
    UserGroupIcon,
    PlusSmallIcon,
    UserMinusIcon,
    UserPlusIcon,
    MoonIcon,
    ExclamationTriangleIcon,
    CheckIcon,
    IdentificationIcon,
    BuildingOffice2Icon,
    BuildingOfficeIcon,
    BuildingLibraryIcon,
    LockClosedIcon,
    BuildingStorefrontIcon,
    PlusIcon,
    MinusIcon
} from '@heroicons/vue/24/outline'

// #endregion - Components Heroi Icons //

// #region APP - Inicialização firebase //

const firebaseConfig = {
    apiKey: "AIzaSyCzLiw3UAbJG11GaimxhRJDZR1MIft74kU",
    authDomain: "auditoria-fb0c9.firebaseapp.com",
    projectId: "auditoria-fb0c9",
    storageBucket: "auditoria-fb0c9.appspot.com",
    messagingSenderId: "1047390142635",
    appId: "1:1047390142635:web:d46809070aa34721d8b7ee",
    measurementId: "G-VWD5BXC5PH"
};

initializeApp(firebaseConfig);

// #endregion APP - Inicialização firebase //

// #region APP - Inicialização API //

let api,apiUpload;
if (isDev)
    api = new API("http://localhost:5005");
else
    api = new API("https://api.auditoria.mm.tec.br");

apiUpload = new API_UPLOAD("https://arquivo.auditoria.mm.tec.br/upload");

// #endregion - Inicialização API //

let app = false;

onAuthStateChanged(getAuth(), async () => {

    if (app === false) {

        let modelos = (await api.get("/models")).data;
        if(modelos.success)
            modelos = modelos.data;
        else
            modelos = {};

        app = createApp(App);

        // #region Components Primevue

        app.component('Accordion', Accordion);
        app.component('AccordionTab', AccordionTab);
        app.component('AutoComplete', AutoComplete);
        app.component('Avatar', Avatar);
        app.component('AvatarGroup', AvatarGroup);
        app.component('Badge', Badge);
        app.component('BlockUI', BlockUI);
        app.component('Breadcrumb', Breadcrumb);
        app.component('Button', Button);
        app.component('Calendar', Calendar);
        app.component('Card', Card);
        app.component('Carousel', Carousel);
        app.component('CascadeSelect', CascadeSelect);
        app.component('Checkbox', Checkbox);
        app.component('Chip', Chip);
        app.component('Chips', Chips);
        app.component('ColorPicker', ColorPicker);
        app.component('Column', Column);
        app.component('ColumnGroup', ColumnGroup);
        app.component('ConfirmDialog', ConfirmDialog);
        app.component('ConfirmPopup', ConfirmPopup);
        app.component('ContextMenu', ContextMenu);
        app.component('DataTable', DataTable);
        app.component('DataView', DataView);
        app.component('DataViewLayoutOptions', DataViewLayoutOptions);
        app.component('DeferredContent', DeferredContent);
        app.component('Dialog', Dialog);
        app.component('Divider', Divider);
        app.component('Dock', Dock);
        app.component('Dropdown', Dropdown);
        app.component('DynamicDialog', DynamicDialog);
        app.component('Fieldset', Fieldset);
        app.component('FileUpload', FileUpload);
        app.component('Galleria', Galleria);
        app.component('Image', Image);
        app.component('InlineMessage', InlineMessage);
        app.component('Inplace', Inplace);
        app.component('InputMask', InputMask);
        app.component('InputNumber', InputNumber);
        app.component('InputSwitch', InputSwitch);
        app.component('InputText', InputText);
        app.component('Knob', Knob);
        app.component('Listbox', Listbox);
        app.component('MegaMenu', MegaMenu);
        app.component('Menu', Menu);
        app.component('Menubar', Menubar);
        app.component('Message', Message);
        app.component('MultiSelect', MultiSelect);
        app.component('OrderList', OrderList);
        app.component('OrganizationChart', OrganizationChart);
        app.component('OverlayPanel', OverlayPanel);
        app.component('Paginator', Paginator);
        app.component('Panel', Panel);
        app.component('PanelMenu', PanelMenu);
        app.component('Password', Password);
        app.component('PickList', PickList);
        app.component('ProgressBar', ProgressBar);
        app.component('ProgressSpinner', ProgressSpinner);
        app.component('RadioButton', RadioButton);
        app.component('Rating', Rating);
        app.component('Row', Row);
        app.component('SelectButton', SelectButton);
        app.component('ScrollPanel', ScrollPanel);
        app.component('ScrollTop', ScrollTop);
        app.component('Slider', Slider);
        app.component('Sidebar', Sidebar);
        app.component('Skeleton', Skeleton);
        app.component('SpeedDial', SpeedDial);
        app.component('SplitButton', SplitButton);
        app.component('Splitter', Splitter);
        app.component('SplitterPanel', SplitterPanel);
        app.component('Steps', Steps);
        app.component('TabMenu', TabMenu);
        app.component('TabView', TabView);
        app.component('TabPanel', TabPanel);
        app.component('Tag', Tag);
        app.component('Textarea', Textarea);
        app.component('Terminal', Terminal);
        app.component('TieredMenu', TieredMenu);
        app.component('Timeline', Timeline);
        app.component('Toast', Toast);
        app.component('Toolbar', Toolbar);
        app.component('ToggleButton', ToggleButton);
        app.component('Tree', Tree);
        app.component('TreeSelect', TreeSelect);
        app.component('TreeTable', TreeTable);
        app.component('TriStateCheckbox', TriStateCheckbox);
        app.component('VirtualScroller', VirtualScroller);

        // Auxiliaries
        app.component('Loading', Loading);
        app.component('ThemeSwitcher', ThemeSwitcher);
        app.component('Tailwind', Tailwind);

        // #endregion Components Primevue // 

        // #region - Components Heroi Icons //

        app.component('Bars3Icon', Bars3Icon);
        app.component('BellIcon', BellIcon);
        app.component('BuildingOfficeIcon', BuildingOfficeIcon);
        app.component('BuildingOffice2Icon', BuildingOffice2Icon);
        app.component('BuildingLibraryIcon', BuildingLibraryIcon);
        app.component('BuildingStorefrontIcon', BuildingStorefrontIcon);
        app.component('CalendarIcon', CalendarIcon);
        app.component('Cog6ToothIcon', Cog6ToothIcon);
        app.component('ChartPieIcon', ChartPieIcon);
        app.component('CheckIcon', CheckIcon);
        app.component('ChevronDownIcon', ChevronDownIcon);
        app.component('ChevronRightIcon', ChevronRightIcon);
        app.component('ChevronDoubleRightIcon', ChevronDoubleRightIcon);
        app.component('ChevronDoubleLeftIcon', ChevronDoubleLeftIcon);
        app.component('DocumentDuplicateIcon', DocumentDuplicateIcon);
        app.component('EnvelopeIcon', EnvelopeIcon);
        app.component('ExclamationTriangleIcon', ExclamationTriangleIcon);
        app.component('EyeIcon', EyeIcon);
        app.component('FolderIcon', FolderIcon);
        app.component('FolderPlusIcon', FolderPlusIcon);
        app.component('HomeIcon', HomeIcon);
        app.component('IdentificationIcon', IdentificationIcon);
        app.component('LockClosedIcon', LockClosedIcon);
        app.component('MagnifyingGlassIcon', MagnifyingGlassIcon);
        app.component('MinusIcon', MinusIcon);
        app.component('MoonIcon', MoonIcon);
        app.component('PencilIcon', PencilIcon);
        app.component('PencilSquareIcon', PencilSquareIcon);
        app.component('PlusSmallIcon', PlusSmallIcon);
        app.component('PlusIcon', PlusIcon);
        app.component('PrinterIcon', PrinterIcon);
        app.component('TrashIcon', TrashIcon);
        app.component('UserGroupIcon', UserGroupIcon);
        app.component('UserIcon', UserIcon);
        app.component('UsersIcon', UsersIcon);
        app.component('XMarkIcon', XMarkIcon);
        app.component('UserMinusIcon', UserMinusIcon);
        app.component('UserPlusIcon', UserPlusIcon);

        // #endregion - Components Heroi Icons //

        app.config.globalProperties.$api = api;
        app.config.globalProperties.$uapi = apiUpload;
        app.config.globalProperties.$utils = Utils;
        app.config.globalProperties.$modelos = modelos;

        const MyDesignSystem = {
            ...TButton, ...TSpeedDial, ...TSplitButton, ...TCharts, ...TDataTable, ...TDataView, ...TOrderList, ...TOrgChart, ...TPaginator, ...TPickList, ...TTimeline,
            ...TTree, ...TUpload, ...TAutoComplete, ...TCalendar, ...TCascadeSelect, ...TCheckbox, ...TChips, ...TColorPicker, ...TDropdown, ...TEditor,
            ...TInputMask, ...TInputNumber, ...TInputSwitch, ...TInputText, ...TKnob, ...TListbox, ...TMultiSelect, ...TPassword, ...TRadioButton,
            ...TRating, ...TSelectButton, ...TSlider, ...TTextarea, ...TToggleButton, ...TTreeSelect, ...TTriStateCheckbox, ...TCarousel, ...TGalleria,
            ...TImage, ...TBreadcrumb, ...TContextMenu, ...TDock, ...TMegaMenu, ...TMenu, ...TMenubar, ...TPanelMenu, ...TSteps, ...TTabMenu,
            ...TTieredMenu, ...TInlineMessage, ...TMessage, ...TToast, ...TAvatar, ...TBadge, ...TBlockUI, ...TChip, ...TInplace, ...TProgressBar,
            ...TRipple, ...TScrollTop, ...TSkeleton, ...TTag, ...TTerminal, ...TConfirmDialog, ...TConfirmPopup, ...TDialog, ...TDynamicDialog,
            ...TOverlayPanel, ...TSidebar, ...TTooltip, ...TAccordion, ...TCard, ...TDivider, ...TFieldset, ...TPanel, ...TScrollPanel, ...TSplitter,
            ...TTabView, ...TToolbar, ...ThemeSwitcher
        }

        app.use(PrimeVue,
            {
                ripple: true,
                unstyled: true,
                pt: MyDesignSystem,
                locale: {
                    accept: "Sim",
                    reject: "Não",
                    choose: "Selecionar",
                    upload: "Upload",
                    cancel: "Cancelar",
                    dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
                    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
                    monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
                    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
                    today: "Hoje",
                    weekHeader: "Sm",
                    firstDayOfWeek: 0,
                    dateFormat: "dd/mm/yy",
                    weak: "Fraca",
                    medium: "Média",
                    strong: "Forte",
                    passwordPrompt: "Informe uma senha"
                }
            },

        );

        // #region directive primevue

        app.directive('tooltip', Tooltip);
        app.directive('badge', BadgeDirective);
        app.directive('ripple', Ripple);
        app.directive('styleclass', StyleClass);
        app.directive('focustrap', FocusTrap);

        // #endregion directive primevue

        app.use(ConfirmationService);
        app.use(ToastService);
        app.use(DialogService);
        app.use(LoadingService);
        app.use(router);
        app.mount('#app')

    }
});
