export default {
    megamenu: {
        root: ({ props }) => ({
            class: ['bg-gray-100 dark:bg-gray-900  border border-gray-300 dark:border-blue-900/40  rounded-md', 'flex relative', { 'p-2 items-center': props.orientation == 'horizontal', 'flex-col w-48 p-0 py-1': props.orientation !== 'horizontal' }]
        }),
        menu: {
            class: ['m-0 sm:p-0 list-none relative', 'outline-none', 'flex items-center flex-wrap flex-row top-auto left-auto relative bg-transparent shadow-none w-auto']
        },
        menuitem: ({ props }) => ({
            class: [
                'relative',
                {
                    'w-auto': props.horizontal,
                    'w-full': !props.horizontal
                }
            ]
        }),
        content: ({ props, context }) => ({
            class: [
                'transition-shadow duration-200',
                { 'rounded-md': props.level < 1 && props.horizontal },
                {
                    'text-gray-700 dark:text-white/80': !context.focused && !context.active,
                    'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90': context.focused && !context.active,
                    'bg-blue-100 text-blue-700 dark:bg-blue-400 dark:text-white/80': context.focused && context.active,
                    'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:text-white/80': !context.focused && context.active
                },
                {
                    'hover:text-gray-700 dark:hover:text-white/80 hover:bg-gray-200 dark:hover:bg-gray-800/80': !context.active,
                    'hover:bg-blue-200 dark:hover:bg-blue-500': context.active
                }
            ]
        }),
        action: {
            class: ['select-none', 'cursor-pointer flex items-center no-underline overflow-hidden relative', 'py-3 px-5 select-none']
        },
        icon: {
            class: 'mr-2'
        },
        submenuicon: ({ props }) => ({
            class: [
                {
                    'ml-2': props.horizontal,
                    'ml-auto': !props.horizontal
                }
            ]
        }),
        panel: ({ props }) => ({
            class: [
                'py-1 bg-white dark:bg-gray-900 border-0 shadow-md w-auto',
                'absolute z-10',
                {
                    'left-full top-0': !props.horizontal
                }
            ]
        }),
        grid: 'flex',
        column: 'w-1/2',
        submenu: {
            class: ['m-0 list-none', 'py-1 w-48']
        },
        submenuheader: {
            class: ['m-0 py-3 px-5 text-gray-700 dark:text-white/80 bg-white dark:bg-gray-900 font-semibold rounded-tr-md rounded-tl-md']
        }
    }
}

