export default {
    toolbar: {
        root: {
            class: ['flex items-center justify-between flex-wrap', 'bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-blue-900/40  p-5 rounded-md gap-2']
        },
        start: 'flex items-center',
        center: 'flex items-center',
        end: 'flex items-center'
    }
}
