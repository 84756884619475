<template>
    <div>
        <button type="button" class="h-[28px] w-[28px] lg:h-[32px] lg:w-[32px] lg:bg-gray-500-f7 bg-slate-50 dark:bg-slate-900 lg:dark:bg-slate-900 text-slate-900 dark:text-white cursor-pointer rounded-full text-[20px] flex flex-col items-center justify-center" @click="onThemeToggler">
            <!-- <i class="dark:text-white pi" /> -->
            <Icon :icon="`${iconClass}`" class="dark:text-white" />
        </button>
    </div>


</template>

<script>
import { Icon } from '@iconify/vue';

export default {

    components: {
        Icon,
    },

    data() {
        return {
            iconClass: 'line-md:sunny-outline-to-moon-alt-loop-transition',
        }
    },

    mounted() {

        this.iconClass = (!this.$root.isDark)?'line-md:sunny-outline-to-moon-alt-loop-transition' : 'line-md:moon-filled-to-sunny-filled-loop-transition';

    },

    methods: {

        onThemeToggler() {
            const root = document.getElementsByTagName('html')[0];

            root.classList.toggle('dark');
            this.$root.isDark = root.classList.contains('dark');

            window.localStorage.setItem('AUDITORIA/MODO',this.$root.isDark?'dark':'light');

            this.iconClass = (!this.$root.isDark)?'line-md:sunny-outline-to-moon-alt-loop-transition' : 'line-md:moon-filled-to-sunny-filled-loop-transition';
        },
    }
};


</script>